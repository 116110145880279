.header {
  text-align: center;
}

.question {
  font-size: 50px;
  display: 'block';
  font-family: 'Quran';
}

.questionArea {
	margin-top: 10px;
	margin-bottom: 10px;
}


.selected {
  font-weight: bold;
  border-style: dotted;
}

.selectedr {
	color: red;
	font-weight: bold;
	border-style: dotted;
} 

.wrong {
	color: red;
	font-weight: bold;
} 

.menuItem {
	font-size: 20px !important;
}

/* span.madda_necessary:hover {
  color: white;
	background-color: #bc2ceb;
}

span.qalaqah:hover {
  color: white;
	background-color: #027bfd;
}

span.madda_permissible:hover {
  color: white;
	background-color: #ff7600;
}

span.ham_wasl:hover {
  color: white;
	background-color: #929292;
}

span.ghunnah:hover {
  color: white;
	background-color: #24980b;
}

span.slnt:hover {
  color: white;
	background-color: #929292;
}

span.laam_shamsiyah:hover {
  color: white;
	background-color: #929292;
}

span.madda_normal:hover {
  color: white;
	background-color: #e5ab35;
}

span.madda_obligatory:hover {
  color: white;
	background-color: #892e39;
}

span.ikhafa:hover {
  color: white;
	background-color: #24980b;
}

span.idgham_shafawi:hover {
  color: white;
	background-color: #929292;
}

span.idgham_ghunnah:hover {
  color: white;
	background-color: #929292;
}

span.iqlab:hover {
  color: white;
	background-color: #24980b;
}

span.ikhafa_shafawi:hover {
  color: white;
	background-color: #929292;
}

span.idgham_mutajanisayn:hover {
  color: white;
	background-color: #929292;
}

span.idgham_mutaqaribayn:hover {
  color: white;
	background-color: #929292;
}

span.idgham_wo_ghunnah:hover {
  color: white;
	background-color: #929292;
} */

.spacing {
	margin-bottom: 10px;
}

.animated {
	/* Start the shake animation and make the animation last for 0.5 seconds */
	animation: shake 0.5s;
  
	/* When the animation is finished, start again */
	animation-iteration-count: infinite;
  }

@keyframes shake {
	0% { transform: translate(1px, 1px) rotate(0deg); }
	10% { transform: translate(-1px, -2px) rotate(-1deg); }
	20% { transform: translate(-3px, 0px) rotate(1deg); }
	30% { transform: translate(3px, 2px) rotate(0deg); }
	40% { transform: translate(1px, -1px) rotate(1deg); }
	50% { transform: translate(-1px, 2px) rotate(-1deg); }
	60% { transform: translate(-3px, 1px) rotate(0deg); }
	70% { transform: translate(3px, 1px) rotate(-1deg); }
	80% { transform: translate(-1px, -1px) rotate(1deg); }
	90% { transform: translate(1px, 2px) rotate(0deg); }
	100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.bottom {
	margin-bottom: 80px;
}

.selectAnswer {
	margin-bottom: 30px;
}